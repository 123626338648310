import Vue from 'vue'
import App from './App.vue'
import router from '../src/router/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css'
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})


Vue.use(ElementUI)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
