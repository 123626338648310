import vue from 'vue'
import VueRouter from 'vue-router'
import { getItem } from '@/utils/store'
// 使用路由
vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: '/index' },
        {
            path: '/index', name: 'index', component: () => import('../views/home/index.vue'),
            meta: { title: '领航轻易货收银 ', keepAlive: true },
        },
        { path: '/logi', name: 'logi', component: () => import('../views/logi/logi.vue'), meta: { title: '领航轻易货收银 ', }, },
        { path: '/Meinformation', name: 'Meinformation', component: () => import('../views/information/Meinformation.vue'), meta: { title: '收款' }, },
        { path: '/password', name: 'password', component: () => import('../views/personalCenter/password.vue'), meta: { title: '领航轻易货收银 ', } },
        { path: '/userName', name: 'userName', component: () => import('../views/personalCenter/userName.vue'), meta: { title: '领航轻易货收银 ', } },
        { path: '/user', name: 'user', component: () => import('../views/dispose/user.vue') },
        { path: '/productControl', name: 'productControl', component: () => import('../views/dispose/productControl.vue'), meta: { title: '交接班' }, },
        {
            path: '/home', name: 'home', component: () => import('../views/home/home.vue'),
            meta: { title: '领航轻易货收银 ', },
            children: [
            ]
        },

    ]
})
// 路由守卫设置
router.beforeEach((to, from, next) => {
    // 先判断浏览器中是否已经有token了，有则true，否则false
    const isLogin = localStorage.token ? true : false
    //然后判断要去往的页面，如果是去往logi，就直接放行
    //如果不是去往logi页面，则判断有没有token，如果有token就放行，否则就跳转login页面
    if (to.path == '/logi') {
        next()
    } else {
        isLogin ? next() : next('/logi')
    }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// 重复点击导航，控制台路由报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router